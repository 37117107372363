import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  Icon,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#eee', // TODO: get these from the actual theme
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  noSpacer: {
    display: 'none',
  },
  spacer: {
    display: 'block',
  },
  pagination: {
    width: '50%',
  },
  paginationLeft: {
    order: '-1',
  },
  footerContent: {
    boxSizing: 'border-box',
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '20px',
  },
  tableFooter: {
    width: '100%',
    display: 'flex',
    padding: '10px 0 0 0',
  },
  content: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  skeletonRoot: {
    width: '100%',
  },
  cardHead: {
    display: 'flex',
  },
}));

function LoadingSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.skeletonRoot}>
      <Skeleton animation="wave" className={classes.skeleton} />
      <Skeleton className={classes.skeleton} />
      <Skeleton animation="wave" className={classes.skeleton} />
      <Skeleton className={classes.skeleton} />
    </div>
  );
}

export const Datatable = ({
  tableHeading,
  hasSearch = false,
  defaultSortColumn,
  defaultSortDirection = 'asc',
  paginationLeft = false,
  footerContent,
  loading = false,
  data,
  tableConfig,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState(defaultSortDirection);
  const [orderBy, setOrderBy] = React.useState('created');
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (property) => {
    if (property === orderBy) {
      // the column that is already the sort column was selected, so simply reverse the order
      setOrder(order === 'desc' ? 'asc' : 'desc');
    } else {
      setOrderBy(property);
      setOrder(defaultSortDirection);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // consider adding sort types, specifically for items that do not work well with JS ">" comparison
  const tableSort = (a, b) => {
    const aVal = a[orderBy];
    const bVal = b[orderBy];
    const descending = aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
    return order === 'asc' ? -descending : descending;
  };

  return (
    <Paper elevation={1} className={classes.content}>
      <Typography variant="h6" className={classes.title}>
        {tableHeading}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            {tableConfig.map((cfg) => (
              <TableCell
                className={[classes.smallRow, cfg.headingClass || '']
                  .filter((f) => !!f)
                  .join(' ')}
                style={cfg.headingStyles}
                key={cfg.heading}
                sortDirection={orderBy === cfg.key ? order : false}
                onClick={() => cfg.sortable && handleRequestSort(cfg.key)}
                style={cfg.sortable ? { cursor: 'pointer' } : {}}
              >
                <Typography variant="overline" className={classes.cardHead}>
                  <>
                    {cfg.heading}
                    {cfg.key === orderBy && (
                      <Icon style={{ fontSize: '0.9rem', marginLeft: '5px' }}>
                        {order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
                      </Icon>
                    )}
                  </>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!loading && (
          <TableBody>
            {data
              .sort(tableSort)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, j) => (
                <TableRow key={row.id}>
                  {tableConfig.map((cfg) => (
                    <TableCell
                      className={classes.smallRow}
                      key={`row${j}-${cfg.heading}`}
                    >
                      {typeof cfg.formatter === 'function'
                        ? cfg.formatter(row[cfg.key], row)
                        : row[cfg.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        )}
      </Table>
      {loading && <LoadingSkeleton />}
      <div className={classes.tableFooter}>
        <TablePagination
          className={[
            classes.pagination,
            paginationLeft && classes.paginationLeft,
          ]
            .filter((f) => !!f)
            .join(' ')}
          classes={{
            spacer: paginationLeft ? classes.noSpacer : classes.spacer,
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {footerContent && (
          <div className={classes.footerContent}>{footerContent}</div>
        )}
      </div>
    </Paper>
  );
};
